import { ButtonOutlinePrimary, PaginationPrimary } from '@lambdacurry/component-library';
import { graphql, Link, navigate } from 'gatsby';
import React from 'react';
import { Container, Grid, Layout, Section } from '../../components';
import { Hero } from '../../components/Hero/Hero';
import { PageData, SermonData } from '../../types';
import InnerHTML from 'dangerously-set-html-content';
import classNames from 'classnames';

interface SermonsTemplateProps {
  data: {
    directus: {
      sermon: SermonData[];
      page: PageData[];
    };
  };
  pageContext: {
    count: number;
    humanPageNumber: number;
    limit: number;
    nextPagePath: string;
    numberOfPages: number;
    pageNumber: number;
    previousPagePath: string;
    skip: number;
  };
}

const SermonCard = ({ sermon, className }: { sermon: SermonData; className?: string }) => (
  <div className={classNames('sermon-card', 'bg-white rounded shadow-md p-6 sm:p-8 md:p-10 lg:p-12 mb-8', className)}>
    <Link to={`/sermon/${sermon.id}`}>
      <h2 className="text-green">{sermon.hero_title}</h2>
      <p className="text-lg">{new Date(sermon.date_published).toLocaleDateString('en-US')}</p>
    </Link>
    <InnerHTML className="my-6" html={sermon.content} />
    <InnerHTML html={sermon.buzzsprout_script} />
  </div>
);

const SermonLink = ({ sermon, className }: { sermon: SermonData; className?: string }) => (
  <div
    className={classNames(
      'sermon-link',
      'py-8 md:py-10 lg:py-12',
      'mx-8 md:mx-10 lg:mx-12 md:mt-2 lg:mt-4',
      'border-gray-lighter border-solid border-b-2',
      className
    )}
  >
    <div className="sm:flex align-top">
      <div>
        <Link className="flex-auto" to={`/sermon/${sermon.id}`}>
          <h3 className="text-blue">{sermon.hero_title}</h3>
          <p className="text-lg">{new Date(sermon.date_published).toLocaleDateString('en-US')}</p>
        </Link>
        <InnerHTML className="mt-6" html={sermon.content} />
      </div>
      <ButtonOutlinePrimary
        className="mt-6 sm:mt-0 sm:ml-10 min-w-max"
        as={buttonProps => <Link {...buttonProps} to={`/sermon/${sermon.id}`} />}
      >
        Listen Now
      </ButtonOutlinePrimary>
    </div>
  </div>
);

const Sermons: React.FC<SermonsTemplateProps> = ({ data, pageContext }) => {
  const sermons = data.directus.sermon;
  const pageData = data.directus.page?.length ? data.directus.page[0] : ({} as PageData);

  return (
    <Layout pageData={{ title: 'Past Sermons' }} metadata={{ bodyClassName: 'page page-sermons' }}>
      <Hero data={pageData} />
      <Section>
        <Container>
          <Grid>
            <div className="col-span-12 md:col-span-10 md:col-start-2">
              {sermons.map((sermon, index) =>
                pageContext.humanPageNumber === 1 && index === 0 ? (
                  <SermonCard sermon={sermon} />
                ) : (
                  <>
                    <SermonLink sermon={sermon} />
                  </>
                )
              )}

              <div className="mt-12 flex justify-center">
                <PaginationPrimary
                  onChange={(_, page) => (page === 1 ? navigate('/sermons') : navigate(`/sermons/${page}`))}
                  page={pageContext.humanPageNumber}
                  pagesCount={pageContext.numberOfPages}
                />
              </div>
            </div>
          </Grid>
        </Container>
      </Section>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    directus {
      sermon(offset: $skip, limit: $limit) {
        ...SermonFields
      }
      page(filter: { url: { _eq: "/sermons" } }) {
        hero_title
        hero_type
        hero_image {
          id
          description
          imageFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 50)
            }
          }
        }
      }
    }
  }
`;

export default Sermons;
